import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import React, { useState } from 'react'
import API from 'services/api'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons/button'
import AppModal from 'components/common/modal'

const I18N = i18nPath('views.admin.ticketing.log_list_page')

const DisplayJson = ({ json }: { json: string | object }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (json === null) {
    return <span>—</span>
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  let formattedJson
  try {
    const jsonObj = typeof json === 'string' ? JSON.parse(json) : json
    formattedJson = JSON.stringify(jsonObj, null, 2)
  } catch (e) {
    formattedJson = typeof json === 'string' ? json : JSON.stringify(json)
  }

  return (
    <>
      <Button variant='secondary' onClick={toggleModal}>
        {I18NCommon('show')}
      </Button>

      {isModalOpen && (
        <AppModal
          visible
          toggle={toggleModal}
          size='md'
          modalContentClassName='JsonDisplayModal'
        >
          <div className='TiptapEditorContentWrapper'>
            <pre>
              <code>{formattedJson}</code>
            </pre>
          </div>
        </AppModal>
      )}
    </>
  )
}

const columns = [
  {
    header: I18N('table_header.created_at'),
    col: 'col-created_at',
    id: 'created_at',
    accessor: d => <div>{i18nMoment(d.createdAt).format('ll LT')}</div>,
  },
  {
    header: I18N('table_header.log_type'),
    col: 'col-log_type',
    accessor: d => <div>{d.logType}</div>,
  },
  {
    header: I18N('table_header.uri'),
    col: 'col-uri',
    accessor: d => <div>{d.uri}</div>,
  },
  {
    header: I18N('table_header.request_headers'),
    col: 'col-request_headers',
    accessor: d => <DisplayJson json={d.requestHeaders} />,
  },
  {
    header: I18N('table_header.request_body'),
    col: 'col-request_body',
    accessor: d => <DisplayJson json={d.requestBody} />,
  },
  {
    header: I18N('table_header.response_body'),
    col: 'col-response_body',
    accessor: d => <DisplayJson json={d.responseBody} />,
  },
]

const LogListPage = () => {
  const {
    data,
    isLoading,
    paginationData,
    callApi: fetchLogs,
  } = useFetch(API.admin.ticketing.integrationLogs.fetchAll)

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <SmartTable
          data={data}
          showPagination={paginationData.page !== null && paginationData.totalPages !== null}
          columns={columns}
          isLoading={isLoading}
          page={paginationData.page}
          pages={paginationData.totalPages}
          onPaginationClick={fetchLogs}
          className='white-bg-table'
        />
      </main>
    </>
  )
}

export default LogListPage
